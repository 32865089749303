.notification-icon{
  font-size: 32px;
}
.unread-icon{
  font-size: 08px;
}
.trash-icon{
  font-size: 12px;
}
.empty-state-icon{
  font-size: 78px;
  margin: 60px auto 30px;
}