.copyrightTandem {
  width: 100%;
  left: 50%;
  opacity: 1;
  margin-bottom: 0;
  bottom: 20px;
  text-align: center;
  line-height: 1.38;
  font-weight: 400;
  font-size: 10px;
}

.logoImage {
  width: 200px;
}

.auth-field {
  width: 328px;

  input {
    width: 100%;
  }
}

.loginFormFieldDiv {
  margin: 0 auto 1rem;
  max-width: 300px;

  input {
    font-size: 12px;
    line-height: 1.33;
    min-height: 32px;
    letter-spacing: 0.3px;
  }
}
.pb-error_row {
  color: #EC174B;;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.4px;
  padding: 1rem 0;
  i.fa-light {
    font-size: 16px;
  }
  .flex-grow-0{
    min-width: initial;
  }
}
.beqom-logo {
  width: 231px;
  z-index: 9;
  &.small {
    width: 150px;
  }
}
.tandem-logo {
  width: 272px;
  padding-right: 30px;
  z-index:9;
  padding-bottom: 9px;
  position: relative;
  img{
    width: 100%
  }
  &:after{
    position: absolute;
    content: "";
    height: 60px;
    bottom: 0;
    right: 0;
    width: 1px;
    border-left: 1px solid #666;
    opacity: 0.5;
    z-index: 9;
    display: block
  }
  &.small{
    width: 183px;
    padding-right: 17px;
    padding-bottom: 6px;
    &:after{
      height: 44px;
    }
  }
}
@media(max-width: 768px) {
  .beqom-logo{
    width: 147px;
    &.small{
      margin-top:16px;
      width: 143px;
    }
  }
  .tandem-logo{
    width: 178px;
    padding-right: 16px;
    &:after{
      height: 41px;
    }
    &.small{
      margin-top:16px;
      width: 172px;
      padding-right: 14px;
      &:after{
        height: 38px;
      }
    }
  }
}
