$noto: "Noto Sans", sans-serif;

/* Colors */
$color-primary-action: #3db8c7;
$key: #030303;
$white: #fff;
$red: #EC174B;
$faded-red: #e43b4b;
$teal: #3db8c7;
$teal-active: #00cfe7;
$windows-blue: #32a2b3;
$very-light-blue: #e9ecef;
$black: #3b3b3c;
$light-black: #5b5b5d;
$gray: #f3f3f3;
$dark-gray: #9d9d9d;
$light-gray: #929293;
$blue: #2985f8;
$dark-blue: #1f6d99;
$green: #80d135;
$orange: #f4804b;
$yellow: #ffc526;
$border: #dbdbde;
$bg: #dedede;
$body-bg: #fafafa;
$btn-red: rgb(228, 59, 76);
$half-red: rgba(228, 59, 75, 0.35);
$editor-border: #cfcfd2;
$icon-border: rgba(0, 0, 0, 0.05);
$shadow: rgba(59, 59, 60, 0.12);
$btn-shadow: rgba(59, 59, 60, 0.25);
$shadow-border: rgba(146, 146, 147, 0.13);
$highlight: #c4eaee;
$teal-trans: rgba(61, 184, 199, 0.5);
$teal-hover: rgba(61, 184, 199, 0.3);
$teal-light: rgba(61, 184, 199, 0.1);
$error-bkg: rgba(229, 59, 76, 0.1);
$simple-btn-bg: rgba(255, 255, 255, 0.25);
$black-trans: rgba(0, 0, 0, 0.7);
$color-primary-light: #00cfe7;
$color-black: #000;
$color-gray1: #333;
$color-gray2: #757575;
$color-gray3: #919191;
$color-gray4: #BDBDBD;
$color-gray5: #DFDFDF;
$color-gray6: #E6E6E6;
$color-gray7: #F5F5F5;
$color-gray8: #4D4D4D;
$color-gray9: #ebebeb;
$info-bg: rgba(61, 184, 199, 0.1);
$error-bg: rgba(229, 59, 76, 0.1);

//StyleGuide
$charcoal-grey: #3b3b3c;
$black-two: #030303;

//Sizes
$size-36: 2.25rem;
$size-34: 2.125rem;
$size-32: 2rem;
$size-24: 1.5rem;
$size-22: 1.375rem;
$size-21: 1.3125rem;
$size-20: 1.25rem;
$size-18: 1.125rem;
$size-17: 1.063rem;
$size-16: 1rem;
$size-15: 0.9375rem;
$size-14: 0.875rem;
$size-13: 0.8125rem;
$size-12: 0.75rem;
$size-11: 0.6875rem;
$size-10: 0.625rem;

// Spacing
$spacing-xxs: 2px;
$spacing-xs: 4px;
$spacing-s: 6px;
$spacing-sm: 8px;
$spacing-md: 12px;
$spacing-lg: 16px;
$spacing-xlg: 32px;

$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;
