.tile-icon {
  font-size: 40px;
}
.home-widget-tile {
  height: 170px;
  display: block;
  margin: 8px auto;
}
.notification-widget-tile {
  height: 356px;
  display: block;
  margin: 8px auto 0;
}
@media (max-width: 1600px) {
  .home-widget-tile {
    height: 190px;
  }
  .notification-widget-tile{
    height: 396px
  }
}
@media (max-width: 1300px) {
  .home-widget-tile {
    height: 200px;
  }
  .notification-widget-tile{
    height: 416px
  }
}
@media (max-width: 1200px) {
  .home-widget-tile {
    height: 220px;
  }
  .notification-widget-tile{
    height: 456px
  }
}
@media (max-width: 1050px) {
  .home-widget-tile {
    height: 240px;
  }
  .notification-widget-tile{
    height: 496px
  }
}
@media (max-width: 1024px) {
  .home-widget-tile {
    height: 170px;
  }
  .notification-widget-tile {
    height: 356px;
  }
}