.search-wrapper {
  position: relative;
  .search-dropdown{
    position: absolute;
    top: 54px;
    max-width: 100%;
    z-index: 2;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
  }
  &.goals-search .search-dropdown {
    position: relative;
    top: 0;
  }
}
.fake-blur-area {
  position: absolute;
  content: '';
  height: 50px;
  width: 100%;
  top: -30px;
}