.user-info {
  height: 42px;
  &.medium {
    padding: 1px 0 1px 12px;
    img {
      width: 32px;
      height: 32px;
    }
  }
  &.small {
    height: 30px;
    padding: 1px 0 1px 4px;
    img {
      width: 28px;
      height: 28px;
    }
  }
  &.smaller {
    height: 26px;
    padding: 1px 0 1px 4px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  &.smallest {
    height: 22px;
    padding: 1px 0 1px 4px;
    .ui-avatar{
      margin-right: 4px;
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
  img {
    border-radius: 50%;
  }
  .user-info-icon-wrapper{
    background-color: #E1DFDD;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
}
.oi-info {
  padding: 3px 0 0 12px;
  height: 42px;
  .oi-icon-wrapper {
    background-color: #E1DFDD;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
}
