.w-100 {
  width: 100%
}
.cursor-pointer {
  cursor: pointer;
}
.truncated-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.mw-100 {
  max-width: 100%;
}
.info-label {
  border-radius: 5px;
  padding: 16px
}
.p-1 {
  padding: 4px;
}
.p-2 {
  padding: 8px;
}
.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.py-2 {
  padding-top: 8px!important;
  padding-bottom: 8px!important;
}
.py-3 {
  padding-top: 16px!important;
  padding-bottom: 16px!important;
}
.px-1 {
  padding-right: 4px!important;
  padding-left: 4px!important;
}
.px-2 {
  padding-right: 8px!important;
  padding-left: 8px!important;
}
.px-3 {
  padding-right: 16px!important;
  padding-left: 16px!important;
}
.p-3 {
  padding: 16px!important;
}
.pr-3 {
  padding-right: 16px!important;
}
.pl-3 {
  padding-left: 16px!important;
}
.pl-2 {
  padding-left: 8px!important;
}
.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.my-3 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mr-3 {
  margin-right: 16px;
}
.mx-3 {
  margin-right: 16px;
  margin-left: 16px;
}
.mb-2 {
  margin-bottom: 8px;
}
.mb-3 {
  margin-bottom: 16px;
}
.pb-1 {
  padding-bottom: 4px;
}
.pt-1 {
  padding-top: 4px;
}
.pt-3 {
  padding-top: 16px!important;
}
.pl-3 {
  padding-left: 16px!important;
}
i.popup-icon {
  font-size: 180px;
  margin: 32px auto;
}
.popup-btns{
  margin: 66px auto 0;
}
.margin-32{
  margin: 32px auto;
}
.circle-bar__size-xs.CircularProgressbar {
  height: 56px;
  width: 56px;
}
.height-auto{
  height: auto!important;
}
.tabs-menu {
  .ui-menu {
    border-bottom-width: 0!important;
  }
  .ui-menu__itemwrapper:first-child {
    margin-left: 0;
    padding-left: 0;
  }
  a.ui-menu__item{
    padding-bottom: 14px;
    border-bottom-width: 2px;
    &.jh {
      padding-bottom: 16px;
    }
    &:hover {
      padding-bottom: 14px;
      border-bottom-width: 2px;
    }
  }
}
.custom-divider {
  opacity: 50%
}
.display-none {
  display: none;
}
.overflow-scroll {
  overflow: scroll;
}
body{
  min-height: 100vh;
  #root{
    min-height: 100%;
    .ui-provider {
      min-height: 100%;
    }
  }
}
.flex-grow-1{
  flex-grow: 1
}