@import 'core/core';
@import 'components/components';

* {
  box-sizing: border-box;
  /* outline: 1px dashed #f00; */
}

body {
  margin: 5em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page {
  margin: auto;
  width: 80%;
  padding-top: 4rem;
  &.action-modal{
    display: block;
    margin: 0;
    padding: 1rem;
  }
}
.mb-medium {
  margin-bottom: 15px;
}
.my-medium {
  margin-bottom: 15px;
  margin-top: 15px;
}

.narrow {
  max-width: 900px;
  margin: 0 auto;
}

.page-padding {
  padding: 4rem;
}

.welcome.page > .narrow > img {
  display: block;
  width: 200px;
}

.welcome.page > .narrow > ul {
  width: 80%;
  justify-content: space-between;
  margin: 4rem auto;
  border-bottom: none;
}

.welcome.page > .narrow > ul > li {
  background-color: inherit;
  margin: auto;
}

.welcome.page > .narrow > ul > li > a {
  font-size: 14px;
  min-height: 32px;
  border-bottom-color: rgb(98, 100, 167);
}

.center {
  text-align: center;
}

.sections > * {
  margin: 4rem auto;
}

.fullWidth {
  width: 100%;
}
@media(max-width: 1024px) {
  .page {
    margin: 0;
    width: 100vw;
    padding: 1rem;
    min-height: 100%;
  }
}