@import 'variables';

$spacing-directions: (top, right, bottom, left);
$spacing-sizes: (xxs: $spacing-xxs, xs: $spacing-xs, sm: $spacing-sm, md: $spacing-md, lg: $spacing-lg, xlg: $spacing-xlg);

@each $direction in $spacing-directions {
  @each $size, $value in $spacing-sizes {
    .spacing-#{$direction}-#{$size} {
      margin-#{$direction}: $value;
    }
  }
}
